import { GetCategoriesQuery } from "@/query/posts"
import AppPanel from "../AppPanel"
import { Icon } from "@iconify/react"
import { AnimatePresence, motion } from "framer-motion"
import { Loader } from "@mantine/core"
import ErrorLoadData from "../ErrorLoadData"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
function Categories() {
    const [filter, setFilter] = useState({
        "filter[orderby]": "order_count",
        "order": "desc"
    })
    const [getCategory, setGetCategory] = useState(null)
    const { data: categoriesData, isLoading: CategoryLoading, isError } = GetCategoriesQuery(filter)
    const { data: categoryDetails, isLoading: CategoryDetailsLoading, isError: CategoryDetailsIsError } = GetCategoriesQuery({
        ...filter,
        parent: getCategory
    }, { enabled: Boolean(getCategory) })
    const navigate = useNavigate()
    if (CategoryLoading) {
        return <div className='flex items-center justify-center p-4 h-60'><Loader size={44} /></div>
    }

    if (!categoriesData || isError)
        return <ErrorLoadData />
    const containerVariants = {
        hidden: (i: number) => ({
            opacity: 0,
            y: 70,
            transition: { type: 'spring', delay: 0.5 * i }
        }),
        visible: (i: number) => ({
            opacity: 1,
            y: 0,
            transition: { type: 'spring', delay: 0.1 * i }
        }),
        exit: {
            y: 70,
            transition: { ease: 'easeInOut' }
        }
    }
    return (
        <div className="container -mt-14">
            <div className="grid grid-cols-3 gap-5 max-sm:grid-cols-1">
                {categoriesData?.sort((a, b) => Number(a.order_count) - Number(b.order_count)).map((item, i) => {
                    if (item.id !== 1 && item.parent === 0)
                        return (
                            <motion.span
                                variants={containerVariants}
                                initial="hidden"
                                animate="visible"
                                className="block cursor-pointer"
                                whileHover={{ y: getCategory == item.id ? 0 : -12 }}
                                custom={i}
                                exit="exit"
                                onClick={() => {
                                    if (item.subcategories_count == 0) {
                                        navigate(item.slug == 'mises-a-jour-du-produit' ? 'https://www.cafyb.com/mise-a-jour/' : `/category/${item.slug}`)
                                    } else {
                                        setGetCategory(item.id)
                                    }
                                }
                                }
                                key={item.id}>
                                <AppPanel>
                                    <div className={`flex gap-2 p-7 h-44 ${getCategory == item.id ? 'bg-red-100' : ''}`}>
                                        <div className="w-16">
                                            <Icon className="w-12 h-12" icon={item?.icon_name} />
                                        </div>
                                        <div className="w-[calc(100%-64px)]">
                                            <h2 className="text-lg font-bold dark:text-[#b93840]">{item.name}</h2>
                                            <p className="mt-4 text-base leading-6 opacity-75 dark:text-[#b93840]">
                                                {item.description.length > 80 ?
                                                    `${item.description.substring(0, 80)}...` : item.description
                                                }
                                            </p>
                                        </div>
                                    </div>
                                </AppPanel>
                            </motion.span>
                        )
                })}
            </div>
            <AnimatePresence>
                {CategoryDetailsLoading ?
                    <div className='flex items-center justify-center p-4 h-60'><Loader size={44} /></div> :
                    getCategory && <>
                        <hr className="mt-8" />
                        <div className="grid grid-cols-3 gap-5 mt-8 max-sm:grid-cols-1">
                            {categoryDetails?.sort((a, b) => Number(a.order_count) - Number(b.order_count)).map((item, i) => {
                                if (item.id !== 1 && item.parent > 0)
                                    return (
                                        <motion.span
                                            variants={containerVariants}
                                            initial="hidden"
                                            animate="visible"
                                            className="block cursor-pointer"
                                            whileHover={{ y: -12 }}
                                            custom={i}
                                            exit="exit"
                                            onClick={() => navigate(item.slug == 'mises-a-jour-du-produit' ? 'https://www.cafyb.com/mise-a-jour/' : `/category/${item.slug}`)}
                                            key={item.id}>
                                            <AppPanel>
                                                <div className="flex gap-2 p-7 h-44">
                                                    <div className="w-16">
                                                        <Icon className="w-12 h-12" icon={item?.icon_name} />
                                                    </div>
                                                    <div className="w-[calc(100%-64px)]">
                                                        <h2 className="text-lg font-bold dark:text-[#b93840]">{item.name}</h2>
                                                        <p className="mt-4 text-base leading-6 opacity-75 dark:text-[#b93840]">
                                                            {item.description.length > 80 ?
                                                                `${item.description.substring(0, 80)}...` : item.description
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            </AppPanel>
                                        </motion.span>
                                    )
                            })}
                        </div></>}
            </AnimatePresence>
        </div>
    )
}

export default Categories